import { Fragment, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import { LOAN_JOURNEY_SERVICE } from '../../consts'
import { ObjectType } from '../../@types'
import { formatFromCurrency, formatToCurrency } from '../../functions/format-currency'

const MortgageInformation = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData, onChangeFormData } = useForm()
  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    setPreventBack(false)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Mortgage Information'))
    setStepperActive(true)
    setStepperStep(1)
    setImageRenderer(null)
  }, [isRtl])

  const [fetching, setFetching] = useState(false)
  const [fetchedCities, setFetchedCities] = useState<ObjectType[]>([])

  const getCities = async (search: string): Promise<void> => {
    setFetching(true)

    try {
      const { data } = await axios.get(`${LOAN_JOURNEY_SERVICE}/geo/cities?search=${search}`)

      // @ts-ignore
      setFetchedCities(data.sort((a, b) => a.cityName.S.localeCompare(b.cityName.S)))
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
    }

    setFetching(false)
  }

  useEffect(() => {
    getCities('')
  }, [])

  const onClickContinue = (): void => {
    setMissingInfo(false)

    if (fetching) {
      toast.error(t('Process running in background'))
      return
    }

    if (
      !formData['mortgageHomeType'] ||
      !formData['mortgageHomePlace'] ||
      !formData['mortgageHomeValue'] ||
      !formData['mortgageHomeContracted'] ||
      !formData['mortgageWhenNeeded'] ||
      !formData['mortgagePartners'] ||
      !formData['mortgageRoute'] ||
      !formData['mortgageReturnType']
    ) {
      setMissingInfo(true)
      toast.error(t('Required information is missing'))
      return
    }

    // if (!isCityValid(formData['homeCity'])) {
    //   toast.error(t('Invalid values'))
    //   return
    // }

    // if (
    //   new Date().getTime() - new Date(formData['dateOfBirth']).getTime() < TWENTY_FIVE_YEARS_IN_MS ||
    //   new Date().getTime() - new Date(formData['dateOfBirth']).getTime() > SEVENTY_FIVE_YEARS_IN_MS
    // ) {
    //   failedCallback()
    //   return
    // }

    nextStep()
  }

  return (
    <Fragment>
      <main>
        <div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('סוג הדירה')}</InputLabel>
              <Select
                value={formData['mortgageHomeType']}
                onChange={(e) => onChangeFormData('mortgageHomeType', e.target.value)}
              >
                {[
                  { label: t('חדשה מקבלן'), value: 'חדשה מקבלן' },
                  { label: t('יד שנייה'), value: 'יד שנייה' },
                ].map(({ label, value }) => (
                  <MenuItem key={`mortgageHomeType-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['mortgageHomeType'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <Autocomplete
              disablePortal
              autoHighlight
              options={fetching ? [t('Loading...')] : fetchedCities.map((item) => item.cityName.S)}
              filterOptions={createFilterOptions({
                trim: true,
              })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label={t('מיקום הדירה')}
                  placeholder={t('Tel Aviv')}
                  value={formData['mortgageHomePlace']}
                />
              )}
              value={formData['mortgageHomePlace']}
              onSelect={(e) => {
                // @ts-ignore
                const v = e.target.value

                if (!fetching) {
                  onChangeFormData('mortgageHomePlace', v)
                }
              }}
            />
            <p className='err-txt'>
              {missingInfo && !formData['mortgageHomePlace'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <TextField
              variant='standard'
              label={t('מחיר הדירה')}
              type='string'
              value={formatToCurrency(formData['mortgageHomeValue'] || '')}
              onChange={(e) => {
                const v = formatFromCurrency(e.target.value)

                if (!isNaN(Number(v))) {
                  onChangeFormData('mortgageHomeValue', v)
                }
              }}
              fullWidth
            />
            <p className='err-txt'>
              {missingInfo && !formData['mortgageHomeValue'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('האם נחתם חוזה על הדירה?')}</InputLabel>
              <Select
                value={formData['mortgageHomeContracted']}
                onChange={(e) => onChangeFormData('mortgageHomeContracted', e.target.value)}
              >
                {[
                  { label: t('כן'), value: 'כן' },
                  { label: t('לא'), value: 'לא' },
                  { label: t('בשלושה החודשים הקרובים'), value: 'בשלושה החודשים הקרובים' },
                ].map(({ label, value }) => (
                  <MenuItem key={`mortgageHomeContracted-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['mortgageHomeContracted'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('למתי נדרש הכסף?')}</InputLabel>
              <Select
                value={formData['mortgageWhenNeeded']}
                onChange={(e) => onChangeFormData('mortgageWhenNeeded', e.target.value)}
              >
                {[
                  { label: t('בחודש הקרוב'), value: 'בחודש הקרוב' },
                  { label: t('בשלושה החודשים הקרובים'), value: 'בשלושה החודשים הקרובים' },
                  { label: t('בעוד יותר מ-3 חודשים'), value: 'בעוד יותר מ-3 חודשים' },
                ].map(({ label, value }) => (
                  <MenuItem key={`mortgageWhenNeeded-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['mortgageWhenNeeded'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('האם יש שותפים למשכנתא?')}</InputLabel>
              <Select
                value={formData['mortgagePartners']}
                onChange={(e) => onChangeFormData('mortgagePartners', e.target.value)}
              >
                {[
                  { label: t('לבד'), value: 'לבד' },
                  { label: t('עם שתוף/ים נוסף/ים'), value: 'עם שתוף/ים נוסף/ים' },
                ].map(({ label, value }) => (
                  <MenuItem key={`mortgagePartners-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['mortgagePartners'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('מסלול משכנתא מבוקש')}</InputLabel>
              <Select
                value={formData['mortgageRoute']}
                onChange={(e) => onChangeFormData('mortgageRoute', e.target.value)}
              >
                {[
                  { label: t('ריבית קבועה לא צמודה'), value: 'ריבית קבועה לא צמודה' },
                  { label: t('ריבית פריים'), value: 'ריבית פריים' },
                  { label: t('ריבית קבועה צמודה'), value: 'ריבית קבועה צמודה' },
                  { label: t('תבחרו עבורי'), value: 'תבחרו עבורי' },
                ].map(({ label, value }) => (
                  <MenuItem key={`mortgageRoute-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['mortgageRoute'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('שיטת החזר')}</InputLabel>
              <Select
                value={formData['mortgageReturnType']}
                onChange={(e) => onChangeFormData('mortgageReturnType', e.target.value)}
              >
                {[
                  { label: t('שפיצר'), value: 'שפיצר' },
                  { label: t('בוליט'), value: 'בוליט' },
                  { label: t('קרן שווה'), value: 'קרן שווה' },
                  { label: t('תבחרו עבורי'), value: 'תבחרו עבורי' },
                ].map(({ label, value }) => (
                  <MenuItem key={`mortgageReturnType-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['mortgageReturnType'] ? t('Required information is missing') : null}
            </p>
          </div>
        </div>
      </main>

      <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
        <GreyButton label={t('Continue')} onClick={onClickContinue} />
      </div>
    </Fragment>
  )
}

export default MortgageInformation
