import { CircularProgress } from '@mui/material'

const LoadingScreen = (): JSX.Element => {
  return (
    <main>
      <div style={{ margin: 'auto' }}>
        <CircularProgress />
      </div>
    </main>
  )
}

export default LoadingScreen
