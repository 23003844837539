import { Fragment, useContext, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { InputLabel, TextField, Typography } from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import Loader from '../../components/Loader'
import GreyButton from '../../components/buttons/GreyButton'
import HandWithCard from '../../vectors/HandWithCard'
import { TRANZILLA_SUPPLIER } from '../../consts'
import { DigitalRequest, GoNogo } from '../../@types'

// @ts-ignore
Date.prototype.addMonths = function (months) {
  var date = new Date(this.valueOf())
  date.setMonth(date.getMonth() + months)
  return date
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(() => resolve(true), ms))

const CreditTermsHebrew = ({ close }: { close: () => void }) => {
  const { t } = useTranslation()
  const { formData, onChangeFormData } = useForm()

  useEffect(() => {
    if (!formData['Credit_Data_Effective_Date__c']) {
      onChangeFormData(
        'Credit_Data_Effective_Date__c',
        // @ts-ignore
        new Date().addMonths(formData['loanTerm']).toISOString().split('T')[0]
      )
    }

    if (!formData['Credit_Data_Transaction_Approved_Ef_Date__c']) {
      onChangeFormData(
        'Credit_Data_Transaction_Approved_Ef_Date__c',
        // @ts-ignore
        new Date().addMonths(formData['loanTerm']).toISOString().split('T')[0]
      )
    }
  }, [])

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: 'var(--lighter-grey)',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 9,
      }}
    >
      <div>
        <Typography variant='h4' textAlign='center' margin={3}>
          טופס הסכמה למסירת נתוני אשראי
        </Typography>

        <div className='flex-col'>
          <div className='flex-row'>
            <TextField
              variant='standard'
              label={t('First Name')}
              value={formData['firstName']}
              disabled
              style={{ width: '45%', margin: '0.5rem auto' }}
            />
            <TextField
              variant='standard'
              label={t('Last Name')}
              value={formData['lastName']}
              disabled
              style={{ width: '45%', margin: '0.5rem auto' }}
            />
          </div>

          <TextField
            variant='standard'
            label={t('National ID')}
            value={formData['nationalId']}
            disabled
            style={{ width: '90%', margin: '0.5rem auto' }}
          />
        </div>

        <Typography variant='body1' textAlign='start' margin={2}>
          אני מסכים כי דוח אשראי לגבי מלשכת אשראי אשר כולל נתוני אשראי ממאגר נתוני האשראי שבבנק ישראל, יימסר לאמפא
          אשראי ומימון, לשם בחינת בקשתי להתקשר בעסקת/עסקאות אשראי כמפורט להלן:
        </Typography>

        <Typography variant='body1' textAlign='start' fontWeight='bold' margin={2}>
          סוג העסקה: הלוואה למטרת {t(formData['selectedProduct'])}. בעסקה זו הלקוח: חייב.
        </Typography>

        <Typography variant='body1' textAlign='start' margin={2}>
          אם העסקה לא תצא לפועל, תום תוקף ההסכמה יהיה ביום{' '}
          <input
            type='date'
            value={formData['Credit_Data_Effective_Date__c']}
            onChange={(e) => onChangeFormData('Credit_Data_Effective_Date__c', e.target.value)}
            style={{
              width: '6.5rem',
              margin: '0 0.2rem',
              textAlign: 'right',
              background: 'none',
              color: 'var(--red)',
              border: 'none',
              borderBottom: '1px solid var(--grey)',
            }}
          />
        </Typography>

        <Typography variant='body1' textAlign='start' margin={2}>
          ככל שעסקה מהעסקאות המפורטות לעיל תצא לפועל, הסכמתי זו תהיה בתוקף עד תום אותה עסקה או עד לתאריך
          <input
            type='date'
            value={formData['Credit_Data_Transaction_Approved_Ef_Date__c']}
            onChange={(e) => onChangeFormData('Credit_Data_Transaction_Approved_Ef_Date__c', e.target.value)}
            style={{
              width: '6.5rem',
              margin: '0 0.2rem',
              textAlign: 'right',
              background: 'none',
              color: 'var(--red)',
              border: 'none',
              borderBottom: '1px solid var(--grey)',
            }}
          />{' '}
          המוקדם מביניהם.
        </Typography>

        <Typography variant='body1' textAlign='start' margin={2}>
          הסכמתי זו תאפשר ללשכת אשראי למסור לאמפא אשראי ומימון שותפות מוגבלת במהלך כל תקופה זו נתוני אשראי או דוח
          אשראי לשם ניטור יכולתי להמשיך ולעמוד בתנאי העסקה.
        </Typography>

        <Typography variant='body1' textAlign='start' margin={2}>
          ידוע לי כי נתוני האשראי שבמאגר נתוני האשראי שבבנק ישראל, כוללים מידע בדבר עסקאות אשראי, וכן, מידע בדבר
          הליכי חדלות פירעון ושיקום כלכלי, הליכי הוצאה לפועל והגבלות חשבון לפי חוק שיקים ללא כיסוי. הובא בזאת
          לתשומת ליבי כי הסכמתי כאמור, מאפשרת למשתמש בנתוני אשראי ל קבל דירוג אשראי לגבי מלשכת אשראי, לתקופה
          שהוסכמה לעיל, והכל בכפוף לחוק. כמו כן, ידוע לי שמסירת המידע תלויה בהסכמתי ואין אני חייב לתת הסכמה זו.
        </Typography>
      </div>

      <div className='flex-row' style={{ width: '100%', justifyContent: 'space-evenly' }}>
        <GreyButton
          label='מסכים/ה'
          onClick={() => {
            if (
              Date.now() > new Date(formData['Credit_Data_Effective_Date__c']).getTime() ||
              Date.now() > new Date(formData['Credit_Data_Transaction_Approved_Ef_Date__c']).getTime()
            ) {
              toast.error(t('Consent for credit rate dates must be of a future date'))
              return
            }

            onChangeFormData('Consent_Before_Credit_Pool__c', true)
            close()
          }}
          style={{ width: '42%' }}
        />
        <GreyButton
          label='לא מסכים/ה'
          onClick={() => {
            onChangeFormData('Consent_Before_Credit_Pool__c', false)
            toast.error(t('Your consent for credit rates is required in able to continue'))
          }}
          style={{ width: '42%' }}
        />
      </div>
    </div>
  )
}

const Consent = ({
  nextStep,
  failedCallback,
  updateDigitalRequest,
  digitalRequestId,
}: {
  nextStep: () => void
  failedCallback: () => void
  updateDigitalRequest: (
    payload: Partial<DigitalRequest>,
    token: string,
    digitalRequestId: string
  ) => Promise<AxiosResponse<any, any>>
  digitalRequestId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { token, formData, onChangeFormData, loading, setLoading } = useForm()
  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const [openTerms, setOpenTerms] = useState(false)
  const [fields, setFields] = useState<any>(null)
  const [tempId, setTempId] = useState(formData['nationalId'])

  useEffect(() => {
    if (!openTerms) {
      setPreventBack(false)
      setHeaderActive(true)
      setDarkHeading(true)
      setStepTitle(t('Consent to Provide Credit Data'))
      setStepperActive(true)
      setStepperStep(formData['selectedProduct'] === 'Car' ? 5 : 4)
      setImageRenderer(<HandWithCard />)
    } else {
      setHeaderActive(false)
      setStepperActive(false)
      setImageRenderer(null)
    }
  }, [isRtl, openTerms])

  useEffect(() => {
    if (!fields) {
      //@ts-ignore
      const tzlFields = window.TzlaHostedFields.create({
        sandbox: false,
        fields: {
          credit_card_number: {
            selector: '#cc_num',
            placeholder: t('Credit Card Number'),
            tabindex: 1,
          },
          expiry: {
            selector: '#cc_exp',
            placeholder: t('Credit Card Expiry'),
            tabindex: 2,
            version: '1',
          },
          cvv: {
            selector: '#cc_cvv',
            placeholder: t('Credit Card CVV'),
            tabindex: 3,
          },
        },
        styles: {
          input: {
            direction: 'ltr',
            width: '100%',
            height: 'auto',
          },
        },
      })

      setFields(tzlFields)
    }
  }, [])

  const handleTranzillaResponse = (status: GoNogo) => {
    updateDigitalRequest(
      {
        J5_Status__c: status,
        End_Digital_Request__c: !!(status === 'NOGO'),
      },
      token,
      digitalRequestId
    )
      .then(() => {
        if (status === 'GO') {
          onChangeFormData('tranzillaStatus', 'GO')
          nextStep()
        } else if (status === 'NOGO') {
          onChangeFormData('tranzillaStatus', 'NOGO')
          failedCallback()
        }

        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        toast.error(err.message)
        setLoading(false)
      })
  }

  const onClickContinue = async () => {
    if (
      !formData['Consent_Before_Credit_Pool__c'] ||
      !formData['Credit_Data_Effective_Date__c'] ||
      !formData['Credit_Data_Transaction_Approved_Ef_Date__c']
    ) {
      toast.error(t('Your consent for credit rates is required in able to continue'))
      setOpenTerms(true)
      return
    }

    setLoading(true)

    try {
      await updateDigitalRequest(
        {
          Convert_Lead__c: true,
          Consent_Before_Credit_Pool__c: formData['Consent_Before_Credit_Pool__c'],
          Credit_Data_Effective_Date__c: new Date(formData['Credit_Data_Effective_Date__c']),
          Credit_Data_Transaction_Approved_Ef_Date__c: new Date(
            formData['Credit_Data_Transaction_Approved_Ef_Date__c']
          ),
        },
        token,
        digitalRequestId
      )

      // fields.charge(
      //   {
      //     terminal_name: TRANZILLA_SUPPLIER,
      //     amount: '1.00',
      //     currency_code: 'ILS',
      //     response_language: 'english',
      //     tran_mode: 'V',
      //     card_holder_id_number: tempId,
      //   },
      //   async (
      //     error: null | {
      //       error: string
      //       error_description: string
      //       messages: {
      //         code: string
      //         param: string
      //         message: string
      //       }[]
      //     },
      //     response: {
      //       transaction_response: {
      //         success: boolean
      //         error: string
      //       }
      //       errors:
      //         | null
      //         | {
      //             code: string
      //             param: string
      //             message: string
      //           }[]
      //     }
      //   ) => {
      //     if (error) {
      //       console.log(error)

      //       for (let i = 0; i < error?.messages?.length; i++) {
      //         const item = error.messages[i]
      //         toast.error(item.message)
      //         await sleep(100)
      //       }

      //       setLoading(false)
      //     }

      //     if (response) {
      //       if (response.errors && response.errors.length) {
      //         let isNogo = false

      //         response.errors.forEach(({ code }) => {
      //           if (String(code) === '002' || String(code) === '005') {
      //             isNogo = true
      //           }
      //         })

      //         if (isNogo) {
      //           handleTranzillaResponse('NOGO')
      //         } else {
      //           toast.error(
      //             'פרטי הכרטיס שהכנסת אינם תקינים, אנא הכנס פרטי כרטיס אשראי אחר הרשום על שמך שאינו כרטיס חיוב (דביט), ובעל מסגרת פנויה של 5 ₪ לפחות.'
      //           )
      //         }
      //       } else if (response.transaction_response.success) {
      //         handleTranzillaResponse('GO')
      //       } else {
      //         toast.error(t(response.transaction_response.error))
      //         setLoading(false)
      //       }
      //     }
      //   }
      // )

      handleTranzillaResponse('GO')
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <main>
        {openTerms ? <CreditTermsHebrew close={() => setOpenTerms(false)} /> : null}

        <div style={{ padding: '1rem', fontSize: '0.8rem', textAlign: 'start' }}>
          <p>{t('consent_disclaimer_1')}</p>

          <p
            style={{
              lineHeight: '2rem',
              fontSize: '1rem',
              color: 'var(--blue)',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => setOpenTerms(true)}
          >
            {t('Consent to Provide Credit Data Form')}
          </p>

          <p>{t('consent_disclaimer_2')}</p>
        </div>

        <form
          id='payment_form'
          onSubmit={onClickContinue}
          className='flex-col'
          style={{ flex: 1, alignItems: 'unset', justifyContent: 'space-between' }}
        >
          <div style={{ zIndex: 1 }}>
            <div style={{ margin: '1rem 0' }}>
              <InputLabel
                htmlFor='cc_num'
                variant='standard'
                placeholder='1234 1234 1234 1234'
                style={{ margin: '0 0.8rem', textAlign: 'start', color: 'var(--red)', fontSize: '0.8rem' }}
              >
                {t('Credit Card Number')}
              </InputLabel>
              <div id='cc_num' style={{ height: '2rem', borderBottom: '1px dotted var(--darker-grey)' }} />
            </div>

            <div style={{ margin: '1rem 0' }}>
              <InputLabel
                htmlFor='cc_exp'
                variant='standard'
                placeholder='03/27'
                style={{ margin: '0 0.8rem', textAlign: 'start', color: 'var(--red)', fontSize: '0.8rem' }}
              >
                {t('Credit Card Expiry')}
              </InputLabel>
              <div id='cc_exp' style={{ height: '2rem', borderBottom: '1px dotted var(--darker-grey)' }} />
            </div>

            <div style={{ margin: '1rem 0' }}>
              <InputLabel
                htmlFor='cc_cvv'
                variant='standard'
                placeholder='123'
                style={{ margin: '0 0.8rem', textAlign: 'start', color: 'var(--red)', fontSize: '0.8rem' }}
              >
                {t('Credit Card CVV')}
              </InputLabel>
              <div id='cc_cvv' style={{ height: '2rem', borderBottom: '1px dotted var(--darker-grey)' }} />
            </div>

            <div style={{ margin: '1rem 0' }}>
              <TextField
                variant='standard'
                label={t('National ID')}
                // value={formData['nationalId']}
                // disabled
                value={tempId}
                onChange={(e) => setTempId(e.target.value)}
                fullWidth
              />
            </div>
          </div>
        </form>
      </main>

      {!openTerms ? (
        <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
          {loading ? (
            <Loader />
          ) : (
            <GreyButton label={t('Verify identity with credit card')} onClick={onClickContinue} />
          )}
        </div>
      ) : null}
    </Fragment>
  )
}

export default Consent
