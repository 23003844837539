import { useState } from 'react'

const Umbrella = ({ selected }: { selected: boolean }) => {
  const [hover, setHover] = useState(selected)

  return (
    <svg
      width='103'
      height='102'
      viewBox='0 0 103 102'
      fill='none'
      onMouseEnter={() => {
        if (!selected) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        if (!selected) {
          setHover(false)
        }
      }}
    >
      <circle cx='51.5' cy='51' r='51' fill={hover ? 'var(--red)' : 'var(--lighter-grey)'} />
      <path
        d='M36.1087 73.5375C31.3742 68.4641 28.7813 61.7587 28.8709 54.8199C28.9605 47.8812 31.7256 41.2449 36.5894 36.2954C41.4533 31.3459 48.0403 28.4655 54.9765 28.2548C61.9126 28.0442 68.6623 30.5197 73.8175 35.165M73.8175 35.165L36.1087 73.5388'
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M46.5775 62.885C25.3025 34.1875 37.9825 24.32 63.725 45.435M59.065 57.7263L75.125 73.7513'
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M58.9511 57.5854L54.9268 53.561' stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'} />
    </svg>
  )
}

export default Umbrella
