import { useState } from 'react'

const Coins = ({ selected }: { selected: boolean }) => {
  const [hover, setHover] = useState(selected)

  return (
    <svg
      width='103'
      height='102'
      viewBox='0 0 103 102'
      fill='none'
      onMouseEnter={() => {
        if (!selected) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        if (!selected) {
          setHover(false)
        }
      }}
    >
      <circle cx='51.5' cy='51' r='51' fill={hover ? 'var(--red)' : 'var(--lighter-grey)'} />
      <rect
        x='32.625'
        y='57.0156'
        width='24.0625'
        height='7.65625'
        rx='3.82812'
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
      />
      <rect
        x='37'
        y='33.5'
        width='24.0625'
        height='7.65625'
        rx='3.82812'
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
      />
      <rect
        x='32.625'
        y='41.1562'
        width='24.0625'
        height='7.65625'
        rx='3.82812'
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
      />
      <rect
        x='35.9062'
        y='48.8125'
        width='24.0625'
        height='7.65625'
        rx='3.82812'
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
      />
      <ellipse
        cx='59.1392'
        cy='56.1991'
        rx='12.8423'
        ry='12.8553'
        fill={hover ? 'var(--red)' : 'var(--lighter-grey)'}
        stroke={hover ? 'var(--lighter-grey)' : 'var(--red)'}
      />
      <path
        d='M60.6496 57.3333V54.5976C60.6496 54.1278 60.5759 53.8008 60.4285 53.6166C60.2811 53.4324 59.991 53.3402 59.558 53.3402H56.5321V60H55.2748V52.4007H60.1798C60.8061 52.4007 61.2483 52.5481 61.5062 52.8428C61.7733 53.1284 61.9069 53.5982 61.9069 54.2522V57.3333H60.6496ZM57.9691 54.9983H59.2402V59.0604H61.9069C62.2846 59.0604 62.5701 59.0052 62.7635 58.8946C62.9662 58.7841 63.0998 58.6137 63.1642 58.3834C63.2379 58.1531 63.2748 57.8307 63.2748 57.4162V52.4007H64.5321V57.361C64.5321 58.2913 64.3663 58.9637 64.0347 59.3782C63.7123 59.7927 63.155 60 62.3629 60H57.9691V54.9983Z'
        fill={hover ? 'var(--lighter-grey)' : 'var(--red)'}
      />
    </svg>
  )
}

export default Coins
