import { useContext, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import getQuery from '../../functions/get-query'
import LoadingScreen from './LoadingScreen'
import { CreditPurpose, DigitalRequest, Gender } from '../../@types'
import { LOAN_JOURNEY_SERVICE } from '../../consts'

const MULTIPLE_REQUESTS_NUM_OF_MONTHS = 2
const MULTIPLE_REQUESTS_NUM_OF_REQUESTS = 100000

export default function OtpSuccessBackendFunctions({
  nextStep,
  failedCallback,
  createDigitalRequest,
  updateDigitalRequest,
}: {
  nextStep: () => void
  failedCallback: () => void
  createDigitalRequest: (payload: Partial<DigitalRequest>, token: string) => Promise<AxiosResponse<any, any>>
  updateDigitalRequest: (
    payload: Partial<DigitalRequest>,
    token: string,
    digitalRequestId: string
  ) => Promise<AxiosResponse<any, any>>
}): JSX.Element {
  const { isRtl } = useRtl()
  const { token, formData, onChangeFormData } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  const { branchType, branch, contact } = getQuery()

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(false)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [isRtl])

  const run = async (): Promise<void> => {
    // try {
    //   // create a digital request
    //   const createDigitalRequestPayload: Partial<DigitalRequest> = {
    //     Credit_Purpose__c: formData['selectedProduct'] as CreditPurpose,
    //     Requested_Loan_Amount__c: formData['loanAmount'],
    //     Loan_Period_Months__c: formData['loanTerm'],
    //     Monthly_Repayment_Amount__c: formData['monthlyReturn'],

    //     ID_Number__c: String(formData['nationalId']),
    //     First_Name__c: formData['firstName'],
    //     Last_Name__c: formData['lastName'],
    //     Date_Of_Birth__c: new Date(formData['dateOfBirth']),
    //     Gender__c: formData['gender'] as Gender,
    //     Email__c: formData['emailAddress'],
    //     Mobile_Phone__c: formData['mobileNumber'],

    //     City__c: formData['homeCity'],
    //     Street__c: formData['homeStreet'],
    //     House_Number__c: formData['homeNumber'],
    //     Postal_Code__c: formData['homeZipCode'],
    //     OTP_Status__c: 'GO',
    //   }

    //   if (branchType) {
    //     // createDigitalRequestPayload.XXX = branchType
    //   }

    //   if (branch) {
    //     createDigitalRequestPayload.Branch__c = branch
    //   }

    //   if (contact) {
    //     // createDigitalRequestPayload.XXX = contact
    //   }

    //   const { data: { digitalRequestId } = {}, status: createDigitalRequestStatusCode } =
    //     await createDigitalRequest(createDigitalRequestPayload, token)

    //   if (createDigitalRequestStatusCode !== 201) {
    //     throw new Error('Failed to create a Digital Request')
    //   }

    //   if (formData['selectedProduct'] === 'Car') {
    //     // update digital request with vehicle information
    //     const carPayload: Record<string, any> = {
    //       Car_Encumbrance__c: formData['isVehicleSubjected'],
    //     }

    //     if (formData['isVehicleSubjected']) {
    //       carPayload.Balloon_Amount_Requested__c = formData['balloonAmount']
    //       carPayload.license_Number__c = formData['vehicleLicenseNumber']
    //       carPayload.Car_Value__c = formData['vehicleValue']
    //       carPayload.Car_Type__c = formData['vehicleType']
    //       carPayload.Car_Model__c = formData['vehicleBrand'] + ' ' + formData['vehicleModel']
    //       carPayload.Age_Car__c = formData['vehicleAge']
    //       carPayload.Car_Status__c = formData['isVehicleNew'] === 'yes' ? 'New' : 'Used'

    //       const { data: { value: vehicleValueFromApi } = {} } = await axios.post(
    //         `${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/vehicle-value`,
    //         {
    //           tozeretCode: formData['vehicleBrandCode'],
    //           degemCode: formData['vehicleModelCode'],
    //           shnatYitzur: new Date().getFullYear() - formData['vehicleAge'],
    //         },
    //         {
    //           headers: { authorization: `Bearer ${token}` },
    //         }
    //       )
    //       onChangeFormData('vehicleValueFromApi', vehicleValueFromApi)
    //     }

    //     await updateDigitalRequest(carPayload, token, digitalRequestId)
    //   }

    //   const { data } = await axios.get<DigitalRequest[]>(`${LOAN_JOURNEY_SERVICE}/digital-requests-by-phone`, {
    //     headers: {
    //       authorization: `Bearer ${token}`,
    //     },
    //   })

    //   const requestsFromPastVariableMonts = data.filter(
    //     (item) =>
    //       // @ts-ignore
    //       Date.now() - new Date(item.CreatedDate).getTime() <
    //       1000 /*ms*/ * 60 /*s*/ * 60 /*min*/ * 24 /*h*/ * 30 /*days*/ * MULTIPLE_REQUESTS_NUM_OF_MONTHS /*months*/
    //   )

    //   const isMultipuleDigitalRequestsGo = requestsFromPastVariableMonts.length < MULTIPLE_REQUESTS_NUM_OF_REQUESTS

    //   await updateDigitalRequest(
    //     {
    //       Multipule_Digital_Requests__c: !!isMultipuleDigitalRequestsGo ? 'GO' : 'NOGO',
    //     },
    //     token,
    //     digitalRequestId
    //   )

    //   if (!isMultipuleDigitalRequestsGo) {
    //     return failedCallback()
    //   }

    //   // get geolocation score
    //   const { data: { score: geographicScore } = {} } = await axios.get(
    //     `${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/geo/zip-code-score?zipCode=${formData['homeZipCode']}`,
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   onChangeFormData('geographicScore', geographicScore)

    //   const { data: { result: blacklistScore } = {} } = await axios.post(
    //     `${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/blacklist`,
    //     {
    //       fullName: `${formData['firstName']} ${formData['lastName']}`,
    //     },
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )

    //   if (blacklistScore === '10') {
    //     return failedCallback()
    //   }

    //   const { data: creditScoreData = {} } = await axios.post(
    //     `${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/bdi/credit-score`,
    //     {
    //       nationalId: formData['nationalId'],
    //       firstName: formData['firstName'],
    //       lastName: formData['lastName'],
    //     },
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )

    //   const bdiCreditScoreBad = creditScoreData.error || creditScoreData.FlagCertaintyOfDefaultForCWA

    //   if (bdiCreditScoreBad) {
    //     return failedCallback()
    //   }

    //   const {
    //     data: { akamScoreBad },
    //   } = await axios.get(`${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/akam`, {
    //     headers: {
    //       authorization: `Bearer ${token}`,
    //     },
    //   })

    //   if (akamScoreBad) {
    //     return failedCallback()
    //   }

    nextStep()
    // } catch (error: any) {
    //   console.error(error)
    //   toast.error(
    //     'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
    //   )
    // }
  }

  useEffect(() => {
    run()
  }, [])

  return <LoadingScreen />
}
