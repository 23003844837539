import { Fragment, useContext, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import RedButton from '../../components/buttons/RedButton'
import GreyButton from '../../components/buttons/GreyButton'
import Disclaimer from '../../components/Disclaimer'
import Loader from '../../components/Loader'
import QustionMarks from '../../vectors/QustionMarks'
import { DigitalRequest } from '../../@types'

const AdditionalInformation = ({
  nextStep,
  updateDigitalRequest,
  digitalRequestId,
}: {
  nextStep: () => void
  updateDigitalRequest: (
    payload: Partial<DigitalRequest>,
    token: string,
    digitalRequestId: string
  ) => Promise<AxiosResponse<any, any>>
  digitalRequestId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { token, formData, onChangeFormData, loading, setLoading } = useForm()
  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Just a Few More Questions'))
    setStepperActive(true)
    setStepperStep(formData['selectedProduct'] === 'Car' ? 6 : 5)
    setImageRenderer(<QustionMarks />)
  }, [isRtl])

  const onClickContinue = async (): Promise<void> => {
    setMissingInfo(false)

    if (
      !formData['isForeignCitizen'] ||
      !formData['isPublicFigure'] ||
      !formData['hasRefusedCredit'] ||
      !formData['hasGuarantors'] ||
      !formData['hasBankRejectionsPastYear']
    ) {
      setMissingInfo(true)
      toast.error(t('Required information is missing'))
      return
    }

    // setLoading(true)

    // await updateDigitalRequest(
    //   {
    //     Expatriate__c: formData['isForeignCitizen'],
    //     Senior_Public_Office__c: formData['isPublicFigure'],
    //     Have_you_previously_refused_credit__c: formData['hasRefusedCredit'],
    //     Are_There_Guarantors__c: formData['hasGuarantors'],
    //     // formData['hasBankRejectionsPastYear']
    //   },
    //   token,
    //   digitalRequestId
    // )

    // setLoading(false)
    nextStep()
  }

  return (
    <Fragment>
      <main>
        <div>
          <div style={{ margin: '0 0 1rem 0' }}>
            {t('Are you a foreign citizen?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('isForeignCitizen', 'YES')}
                selected={formData['isForeignCitizen'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('isForeignCitizen', 'NO')}
                selected={formData['isForeignCitizen'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['isForeignCitizen'] ? t('Required information is missing') : null}
            </p>
          </div>

          <div style={{ margin: '0.5rem 0' }}>
            {t('Are you a public figure?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('isPublicFigure', 'YES')}
                selected={formData['isPublicFigure'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('isPublicFigure', 'NO')}
                selected={formData['isPublicFigure'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['isPublicFigure'] ? t('Required information is missing') : null}
            </p>
          </div>

          <div style={{ margin: '0.5rem 0' }}>
            {t('Is someone helping you return the loan?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('hasGuarantors', 'YES')}
                selected={formData['hasGuarantors'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('hasGuarantors', 'NO')}
                selected={formData['hasGuarantors'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['hasGuarantors'] ? t('Required information is missing') : null}
            </p>
          </div>

          <div style={{ margin: '0.5rem 0' }}>
            {t('Did the bank reject or return cheques / charges in the past year?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('hasBankRejectionsPastYear', 'YES')}
                selected={formData['hasBankRejectionsPastYear'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('hasBankRejectionsPastYear', 'NO')}
                selected={formData['hasBankRejectionsPastYear'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['hasBankRejectionsPastYear'] ? t('Required information is missing') : null}
            </p>
          </div>

          <div style={{ margin: '0.5rem 0' }}>
            {t(
              'Were you refused to recieve credit or financial asset services, for any reason related to the prohibition of money laundering and/or terrorist financing?'
            )}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('hasRefusedCredit', 'YES')}
                selected={formData['hasRefusedCredit'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('hasRefusedCredit', 'NO')}
                selected={formData['hasRefusedCredit'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['hasRefusedCredit'] ? t('Required information is missing') : null}
            </p>
          </div>

          <Disclaimer>{t('kyc_disclaimer')}</Disclaimer>
        </div>

        <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
          {loading ? <Loader /> : <GreyButton label={t('Continue')} onClick={onClickContinue} />}
        </div>
      </main>
    </Fragment>
  )
}

export default AdditionalInformation
