import React, { ReactNode } from 'react'

const Disclaimer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        margin: '0.5rem 0',
        textAlign: 'start',
        fontSize: '13px',
        fontWeight: '400',
        color: 'var(--grey)',
      }}
    >
      {children}
    </div>
  )
}

export default Disclaimer
