import { useContext, useEffect } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import RoundIconTextUnder from '../../components/buttons/RoundIconTextUnder'
import Car from '../../icons/product/Car'
import Umbrella from '../../icons/product/Umbrella'
import Education from '../../icons/product/Education'
import Coins from '../../icons/product/Coins'
import Tool from '../../icons/product/Tool'
import Star from '../../icons/product/Star'
import Business from '../../icons/product/Business'
import Disclaimer from '../../components/Disclaimer'

const logoSrc = 'https://open-finance-media.s3.eu-central-1.amazonaws.com/logos/transparent-logo-black.png'

const SelectProduct = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData, onChangeFormData } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setMaxSteps, setImageRenderer } = useContext(StepContext)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [isRtl])

  return (
    <main>
      <div>
        <img src={logoSrc} alt='logo' style={{ maxWidth: 150, maxHeight: 80, margin: '0.5rem auto' }} />
        <Typography variant='h4' textAlign='center' fontSize='2rem' fontWeight='300' margin='0'>
          {t('What is the purpose of this loan?')}
        </Typography>
      </div>

      <div className='flex-row-wrap' style={{ justifyContent: 'space-evenly' }}>
        {[
          {
            label: t('Vehicle'),
            value: 'Car',
            icon: () => <Car selected={formData['selectedProduct'] === 'Car'} />,
          },
          {
            label: t('Travel'),
            value: 'Trip',
            icon: () => <Umbrella selected={formData['selectedProduct'] === 'Trip'} />,
          },
          {
            label: t('Education'),
            value: 'Studies',
            icon: () => <Education selected={formData['selectedProduct'] === 'Studies'} />,
          },
          {
            label: t('Obligations'),
            value: 'Liability Coverage',
            icon: () => <Coins selected={formData['selectedProduct'] === 'Liability Coverage'} />,
          },
          {
            label: t('Renovations'),
            value: 'Renovations',
            icon: () => <Tool selected={formData['selectedProduct'] === 'Renovations'} />,
          },
          {
            label: t('Business investment'),
            value: 'Business Investment',
            icon: () => <Business selected={formData['selectedProduct'] === 'Business Investment'} />,
          },
          {
            label: t('Mortgage'),
            value: 'Other',
            icon: () => <Star selected={formData['selectedProduct'] === 'Other'} />,
          },
        ].map((obj) => (
          <RoundIconTextUnder
            key={obj.value}
            label={obj.label}
            icon={obj.icon}
            onClick={() => {
              setMaxSteps(obj.value === 'Car' ? 6 : 5)
              onChangeFormData('selectedProduct', obj.value)
              nextStep()
            }}
          />
        ))}
      </div>

      <Disclaimer>{t('disclaimer_product')}</Disclaimer>
    </main>
  )
}

export default SelectProduct
