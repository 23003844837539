const {
  REACT_APP_LOAN_JOURNEY_SERVICE: LOAN_JOURNEY_SERVICE = 'http://localhost:1045',
  REACT_APP_TRANZILLA_SUPPLIER: TRANZILLA_SUPPLIER = 'ampa2022',
  REACT_APP_AUTH_SERVICE_URL: AUTH_SERVICE_URL = 'http://localhost:8080',
  REACT_APP_CLIENT_ID: CLIENT_ID = '',
} = process.env

const EIGHTEEN_YEARS_IN_MS = 567648000000
const TWENTY_FIVE_YEARS_IN_MS = 788940000000
const SEVENTY_FIVE_YEARS_IN_MS = 2366820000000

export {
  LOAN_JOURNEY_SERVICE,
  AUTH_SERVICE_URL,
  TRANZILLA_SUPPLIER,
  EIGHTEEN_YEARS_IN_MS,
  TWENTY_FIVE_YEARS_IN_MS,
  SEVENTY_FIVE_YEARS_IN_MS,
  CLIENT_ID,
}
