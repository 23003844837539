import React, { useState } from 'react'

const RedButton = ({
  icon: Icon,
  label,
  onClick,
  selected,
}: {
  icon?: React.FunctionComponent
  label: string
  onClick: () => void
  selected?: boolean
}) => {
  const [hover, setHover] = useState(false)

  return (
    <button
      onClick={() => onClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        minWidth: '150px',
        margin: '0.3rem',
        padding: '0.5rem 0',
        background: selected || hover ? 'var(--red)' : 'transparent',
        border: '1px solid var(--red)',
        borderRadius: '2rem',
        color: selected || hover ? 'var(--lighter-grey)' : 'var(--darker-grey)',
        cursor: 'pointer',
      }}
    >
      {Icon ? <Icon /> : null}
      <p style={{ fontSize: '1rem', fontWeight: '300', textAlign: 'center' }}>{label}</p>
    </button>
  )
}

export default RedButton
