import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import Maintenance from '../../vectors/Maintenance'

const MaintenanceScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [])

  return (
    <main>
      <div style={{ margin: 'auto 0' }}>
        <p style={{ fontSize: '2rem', color: 'var(--red)' }}>{t('Under Maintenance')}</p>
        <p>{t('Close the page and try agian later.')}</p>
        <Maintenance />
        <GreyButton label={t('Refresh')} onClick={() => window.location.reload()} />
      </div>
    </main>
  )
}

export default MaintenanceScreen
