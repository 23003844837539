import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useForm } from '../../contexts/FormContext'
import DialogWrapper, { StepContext } from '../../components/DialogWrapper'
import getQuery from '../../functions/get-query'
import MaintenanceScreen from './MaintenanceScreen'
import EndScreen from './EndScreen'
import SelectProduct from './SelectProduct'
import ProductInformation from './ProductInformation'
import VehicleInformation from './VehicleInformation'
import PersonalInformation from './PersonalInformation'
import Otp from './Otp'
import OtpSuccessBackendFunctions from './OtpSuccessBackendFunctions'
import FinancialInformation from './FinancialInformation'
import Consent from './Consent'
import AdditionalInformation from './AdditionalInformation'
import BdiAndFicoAndDataHarbor from './BdiAndFicoAndDataHarbor'
import SignedResults from './SignedResults'
import BankInformation from './BankInformation'
import { DigitalRequest } from '../../@types'
import { LOAN_JOURNEY_SERVICE } from '../../consts'
import MortgageInformation from './MortgageInformation'

const Journey = (): JSX.Element => {
  const { t } = useTranslation()
  const { token, formData } = useForm()
  const query = getQuery()
  const isMaintenance = !!query.maintenance && query.maintenance !== 'false' && query.maintenance !== '0'

  const [doneWithMortgageInfo, setDoneWithMortgageInfo] = useState(true)
  const [allowNextStepAtVehicleInfo, setAllowNextStepAtVehicleInfo] = useState(false)
  const [manualSign, setManualSign] = useState(false)
  const [journeySuccess, setJourneySuccess] = useState(false)
  const [journeyFail, setJourneyFail] = useState(false)
  const [journeyFailMessage, setJourneyFailMessage] = useState('')
  const [allowClearFail, setAllowClearFail] = useState(false)
  const [gotNogo, setGotNogo] = useState(false)

  const [digitalRequestId, setDigitalRequestId] = useState('')

  const createDigitalRequest = async (payload: Partial<DigitalRequest>, _token: string) => {
    // const res = await axios.post(`${LOAN_JOURNEY_SERVICE}/digital-request`, payload, {
    //   headers: {
    //     authorization: `Bearer ${_token}`,
    //   },
    // })

    // setDigitalRequestId(res.data.digitalRequestId)

    // return res

    return (await Promise.resolve()) as unknown as AxiosResponse<any, any>
  }

  const updateDigitalRequest = async (
    payload: Partial<DigitalRequest>,
    _token: string,
    digitalRequestId: string
  ) => {
    // const res = await axios.patch(`${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}`, payload, {
    //   headers: {
    //     authorization: `Bearer ${_token}`,
    //   },
    // })

    // return res

    return (await Promise.resolve()) as unknown as AxiosResponse<any, any>
  }

  const handleOnClickClose = async (step: number): Promise<void> => {
    if (!manualSign) {
      if (journeySuccess) {
        await updateDigitalRequest(
          {
            Client_Approval_Status__c: 'GO',
          },
          token,
          digitalRequestId
        )
        window.location.replace('https://www.5555.co.il')
      } else {
        if (window.confirm(t('Are you sure you want to leave?'))) {
          if (digitalRequestId && step < 11 && !gotNogo) {
            await updateDigitalRequest(
              {
                Client_Approval_Status__c: 'NOGO',
                Reason_for_Rejection__c: 'לקוח בחר להפסיק את התהליך',
              },
              token,
              digitalRequestId
            )
          }
          window.location.replace('https://www.5555.co.il')
        }
      }
    }
  }

  useEffect(() => {
    const cleanup = async () => {
      await handleOnClickClose(0)
    }

    window.addEventListener('beforeunload', cleanup)

    return () => {
      window.removeEventListener('beforeunload', cleanup)
    }
  }, [])

  const onClickBack = (step: number): void => {
    if (step === 2 && formData['selectedProduct'] === 'Other' && doneWithMortgageInfo) {
      setDoneWithMortgageInfo(false)
    } else {
      setDoneWithMortgageInfo(true)
      if (
        step === 4 &&
        (formData['selectedProduct'] !== 'Car' ||
          (formData['selectedProduct'] === 'Car' && !formData['isVehicleSubjected']))
      ) {
        setAllowNextStepAtVehicleInfo(false)
      }
    }
  }

  const clearFail = () => {
    setAllowClearFail(false)
    setJourneyFail(false)
    setJourneyFailMessage('')
  }

  const otpFailed = () => {
    setAllowClearFail(false)
    setJourneyFail(true)
    setJourneyFailMessage('Failed to verify phone number')
  }

  const infoFailed = () => {
    // setAllowClearFail(false)
    setJourneyFail(true)
    setJourneyFailMessage(
      "We're sorry, but according to the information you have given us, at this time we aren't able to approve your loan. Thank you for your time, we'll be happy to be of service to you in the future"
    )
  }

  const tranzillaFailed = () => {
    setAllowClearFail(true)
    setJourneyFail(true)
    setJourneyFailMessage(
      'Sorry, but we cannot proceed with the credit request, because the credit card verification failed. please verify that the card is active, on your name, and has a transactional balance of at least 5 NIS.'
    )
  }

  return (
    <DialogWrapper
      onClickBack={onClickBack}
      onClickClose={(step) => {
        handleOnClickClose(step)
      }}
    >
      <StepContext.Consumer>
        {({ step, increment, decrement }): JSX.Element | null => {
          return isMaintenance ? (
            <MaintenanceScreen />
          ) : manualSign || journeySuccess || journeyFail ? (
            <EndScreen
              isManual={manualSign}
              isSuccess={journeySuccess}
              isFail={journeyFail}
              failMessage={journeyFailMessage}
              allowClearFail={allowClearFail}
              clearFail={clearFail}
              clickConfirm={() => {
                handleOnClickClose(step)
              }}
            />
          ) : (
            (() => {
              switch (step) {
                case 1:
                  if (!doneWithMortgageInfo) {
                    increment()
                  }

                  return (
                    <SelectProduct
                      nextStep={() => {
                        setDoneWithMortgageInfo(false)
                        increment()
                      }}
                    />
                  )

                case 2:
                  if (formData['selectedProduct'] === 'Other' && !doneWithMortgageInfo) {
                    return (
                      <MortgageInformation
                        nextStep={() => {
                          setDoneWithMortgageInfo(true)
                          increment()
                        }}
                      />
                    )
                  } else {
                    return (
                      <ProductInformation
                        nextStep={() => {
                          setAllowNextStepAtVehicleInfo(true)
                          increment()
                        }}
                      />
                    )
                  }

                case 3:
                  if (formData['selectedProduct'] === 'Car' && formData['isVehicleSubjected']) {
                    return <VehicleInformation nextStep={increment} />
                  } else {
                    if (allowNextStepAtVehicleInfo) {
                      increment()
                    } else {
                      decrement()
                    }
                    return <div />
                  }

                case 4:
                  return (
                    <PersonalInformation
                      nextStep={increment}
                      failedCallback={() => {
                        setAllowClearFail(true)
                        infoFailed()
                      }}
                    />
                  )

                case 5:
                  return <Otp nextStep={increment} failedCallback={otpFailed} />

                case 6:
                  return (
                    <OtpSuccessBackendFunctions
                      nextStep={increment}
                      createDigitalRequest={createDigitalRequest}
                      updateDigitalRequest={updateDigitalRequest}
                      failedCallback={() => {
                        setGotNogo(true)
                        setAllowClearFail(false)
                        infoFailed()
                      }}
                    />
                  )

                case 7:
                  return (
                    <FinancialInformation
                      nextStep={increment}
                      updateDigitalRequest={updateDigitalRequest}
                      digitalRequestId={digitalRequestId}
                    />
                  )

                case 8:
                  return (
                    <Consent
                      nextStep={increment}
                      failedCallback={tranzillaFailed}
                      updateDigitalRequest={updateDigitalRequest}
                      digitalRequestId={digitalRequestId}
                    />
                  )

                case 9:
                  return (
                    <AdditionalInformation
                      nextStep={increment}
                      updateDigitalRequest={updateDigitalRequest}
                      digitalRequestId={digitalRequestId}
                    />
                  )

                case 10:
                  return (
                    <BdiAndFicoAndDataHarbor
                      nextStep={increment}
                      manualCallback={() => {
                        setManualSign(true)
                      }}
                      failedCallback={() => {
                        setGotNogo(true)
                        setAllowClearFail(false)
                        infoFailed()
                      }}
                      digitalRequestId={digitalRequestId}
                    />
                  )

                case 11:
                  return <SignedResults nextStep={increment} />

                case 12:
                  return (
                    <BankInformation
                      nextStep={() => {
                        setJourneySuccess(true)
                      }}
                      updateDigitalRequest={updateDigitalRequest}
                      digitalRequestId={digitalRequestId}
                    />
                  )

                default:
                  return <main />
              }
            })()
          )
        }}
      </StepContext.Consumer>
    </DialogWrapper>
  )
}

export default Journey
