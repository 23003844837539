const formatToCurrency = (v: number | string): string => {
  if (v) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'ILS', minimumFractionDigits: 0 }).format(
      Number(v)
    )
  }

  return ''
}

const formatFromCurrency = (v: string): number => {
  if (v) {
    return Number(v.replaceAll(',', '').replace('₪', ''))
  }

  return 0
}

export { formatToCurrency, formatFromCurrency }
