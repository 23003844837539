import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import ManWithWallet from '../../vectors/ManWithWallet'
import { formatToCurrency } from '../../functions/format-currency'
import { SOLO_INIT_FEE_PERCENT, VEHICLE_INIT_FEE_PERCENT } from './ProductInformation'
import Disclaimer from '../../components/Disclaimer'

const SignedResults = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [isRtl])

  const onClickContinue = (): void => {
    nextStep()
  }

  return (
    <main>
      <div className='flex-col'>
        <div>
          <p style={{ fontSize: '2rem', color: 'var(--grey)' }}>
            {formData['firstName']} {formData['lastName']},
          </p>
          <p style={{ fontSize: '1.4rem' }}>{t('result_title')}</p>
          <p
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: 'var(--red)',
              textShadow: '-1px 1px 3px var(--grey)',
            }}
          >
            {formatToCurrency(formData['loanAmount'])}
          </p>

          <p style={{ fontSize: '1.3rem' }}>
            {t('result_pre_term')} - <span style={{ color: 'var(--red)' }}>{formData['loanTerm']}</span>{' '}
            {t('result_post_term')} | {t('result_pre_interest')} +{' '}
            <span style={{ color: 'var(--red)' }}>{formData['loanInterest']}%</span>
          </p>
        </div>

        <div>
          <ManWithWallet />
          <GreyButton label={t('Continue')} onClick={onClickContinue} />
        </div>

        <Disclaimer>
          * {t('result_disclaimer_pre_interest')}
          {(formData['selectedProduct'] === 'Car' ? VEHICLE_INIT_FEE_PERCENT : SOLO_INIT_FEE_PERCENT) * 100}%,{' '}
          {t('result_disclaimer_post_interest')}
          {t('result_disclaimer_pre_date')} {new Date(Date.now() + 12096e5).toLocaleDateString('he-IL')}{' '}
          {t('result_disclaimer_post_date')}
          <br />
          <br />
          {t('result_disclaimer_middle')}
          <br />
          <br />
          {t('result_disclaimer_pre_mail')} {t('result_disclaimer_post_mail')}
        </Disclaimer>
      </div>
    </main>
  )
}

export default SignedResults
