import { useState } from 'react'

const Education = ({ selected }: { selected: boolean }) => {
  const [hover, setHover] = useState(selected)

  return (
    <svg
      width='103'
      height='102'
      viewBox='0 0 103 102'
      fill='none'
      onMouseEnter={() => {
        if (!selected) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        if (!selected) {
          setHover(false)
        }
      }}
    >
      <circle cx='51.5' cy='51' r='51' fill={hover ? 'var(--red)' : 'var(--lighter-grey)'} />
      <path
        d='M65.4437 62.6574L65.775 62.471V62.0909V54.6731L68.225 53.312V63.9038L52 73.0303L35.775 63.9038V53.312L38.225 54.6731V62.0909V62.471L38.5563 62.6574L51.6813 70.0402L52 70.2195L52.3187 70.0402L65.4437 62.6574Z'
        fill={hover ? 'var(--lighter-grey)' : 'var(--red)'}
        stroke={hover ? 'var(--red)' : 'var(--lighter-grey)'}
        strokeWidth='1.3'
      />
      <path
        d='M77.6 40.3942V55.0375H75.15V45.3325V44.2278L74.1843 44.7643L52 57.0885L26.4 42.8666V40.3942L52 27.1201L77.6 40.3942ZM51.6843 54.1112L52 54.2866L52.3157 54.1112L67.3157 45.7774L71.0657 43.6942L70.75 43.1259L71.0657 43.6941L73.6648 42.2502L74.7231 41.6622L73.6483 41.1049L52.2992 30.035L52 29.8799L51.7008 30.035L30.3517 41.1049L29.2769 41.6622L30.3352 42.2502L32.9343 43.6941L32.9343 43.6942L36.6843 45.7774L36.6843 45.7774L51.6843 54.1112Z'
        fill={hover ? 'var(--lighter-grey)' : 'var(--red)'}
        stroke={hover ? 'var(--red)' : 'var(--lighter-grey)'}
        strokeWidth='1.3'
      />
    </svg>
  )
}

export default Education
